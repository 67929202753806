import { HttpClient } from "@angular/common/http";
import {UserModel} from "./models";
import { Injectable } from "@angular/core";
import {BehaviorSubject, Observable, of} from "rxjs";
import {LocalKeys} from "../../shared/enum";
import {AuthUtils} from "../../core/auth/auth.utils";

@Injectable({
  providedIn: 'root',
})
export class UserService{
  private _user: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);
  private _isSystemAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {

  }

  set user(value: UserModel)
  {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<UserModel>
  {
    return this._user.asObservable();
  }

  set isSystemAdmin(value: boolean)
  {
    // Store the value
    this._isSystemAdmin.next(value);
  }

  get isSystemAdmin$(): Observable<boolean>
  {
    return this._isSystemAdmin.asObservable();
  }

  getUserInformation(){
    const token = localStorage.getItem(LocalKeys.ACCESS_TOKEN);

    const decodedToken = AuthUtils._decodeToken(token);
    this._user.next({
      userName: decodedToken.preferred_username,
      email: decodedToken.email,
      id: decodedToken.sub,
    });
    // const url = '/v1/Admin/user-profile';
    //
    // this.httpClient.get(url).subscribe((user: UserModel) => {
    //     this.setUser(user);
    //     this._user.next(user);
    //   });
  }

  setUser(user: UserModel){
    // localStorage.setItem(LocalKeys.USER, JSON.stringify(user));
  }

  getCurrentUser(){
    const user = localStorage.getItem(LocalKeys.USER);
    if (user) {
      return JSON.parse(user);
    }
    return this.getUserInformation();
  }

  changeMyPassword(oldPassword: string, newPassword: string) {
    const url = `/v1/sso/users/change-password`;

    return this.httpClient.put(url, { oldPassword: oldPassword, newPassword: newPassword });
  }
}
