import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {LoginModel} from "./models";
import {Observable, of, switchMap, tap, throwError } from "rxjs";
import {AuthUtils} from "../../core/auth/auth.utils";
import {UserService} from "../user";
import {LocalKeys} from "../../shared/enum";
import {PermissionService} from "../permission";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authenticated: boolean = false;

  constructor(private httpClient: HttpClient, private _userService: UserService, private _permissionService: PermissionService) {
  }

  set accessToken(token: string)
  {
    localStorage.setItem(LocalKeys.ACCESS_TOKEN, token);
  }

  get accessToken(): string
  {
    return localStorage.getItem(LocalKeys.ACCESS_TOKEN) ?? '';
  }

  set refreshToken(token: string)
  {
    localStorage.setItem(LocalKeys.REFRESH_TOKEN, token);
  }

  get refreshToken(): string
  {
    return localStorage.getItem(LocalKeys.REFRESH_TOKEN) ?? '';
  }

  login(model: LoginModel) {

    if ( this._authenticated )
    {
      return throwError('User is already logged in.');
    }

    const url = '/v1/sso/authentications/sign-in';

    return this.httpClient.post(url, model).pipe(
      tap(async(data: any) => {
        this.accessToken = data.accessToken;
        this.refreshToken = data.refreshToken;

        // Set the authenticated flag to true
        this._authenticated = true;
        return this._userService.getUserInformation();
      }),
    );

  }

  logOut()
  {
    localStorage.removeItem(LocalKeys.ACCESS_TOKEN);
    localStorage.removeItem(LocalKeys.REFRESH_TOKEN);

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  check()
  {
    // Check the access token availability
    if ( !this.accessToken )
    {
      return of(false);
    }

    // If the access token exists, and it didn't expire, sign in using it
    return of(true);
  }

  checkSystemAdmin()
  {
    // Check the access token availability
    const decodedToken = AuthUtils._decodeToken(this.accessToken);
    if(decodedToken.realm_access.roles.findIndex(x => x == 'SystemAdminLottery') > -1){
      return of(true);
    }
    return of(false);
  }

  refreshTokenFunc(){
    const url = '/v1/sso/authentications/refresh-token';
    return this.httpClient.post(url, {refreshToken: this.refreshToken}).pipe(
      switchMap(async(data: any) => {
        this.accessToken = data.accessToken;
        this.refreshToken = data.refreshToken;
      }),
    );
  }

  signOut(): Observable<any>
    {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        this._authenticated = false;
        return of(true);
    }
}
